import React, { useEffect } from 'react';

import InnerHeader from './InnerHeader';
import Footer from './Footer';

import ScrollToTop from 'react-scroll-to-top';

const Boschlayout = ({ children, isSticky, wrapperClass }) => {
  useEffect(() => { }, []);
  
    return (
      <div className={wrapperClass}>
        <InnerHeader isSticky={isSticky} />
        <main className='main-wrapper' id='main-wrapper'>
          {children}
        </main>
        <Footer />
        <ScrollToTop smooth className='fbh-scroll-to-top' />
      </div>
    );
};

export default Boschlayout;
