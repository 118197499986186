import React, { useState } from "react";

import indiaMap from "assets/images/bosch/india-map.png";

import indicatorleaf from "assets/images/bosch/local-indicator-leaf.png";

import downarrow from "assets/images/bosch/downarrow.png";

import kanhashanthivanam from "assets/images/bosch/kanha-shanthi-vanam.png";

import coimbatoresatellite from "assets/images/bosch/cob_plantation_drive.jpg";

import bangaloresatellite from "assets/images/bosch/bangalore-saletallite.png";

import Boschlayout from "../boschlayout/inner";

import SEO from "components/seo/index";

import { Link } from "gatsby";

import { Modal, Button } from "react-bootstrap";

import punesoil from "assets/images/bosch/pune-soil.png";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";



export default function TrackYourForests() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        {/* <div className='arrow-navigations custom-arrow-navigation-event'>
          <button className='pr-custom zcaler-top-slide-button' onClick={() => previous()}>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button className='pr-rightcustom zcaler-top-slide-button zscalar-right-custom' onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <SEO title="BGSW Forest Plantation Drive" />
      <Boschlayout>
        <div className="track-forests-banner track-forests-listing pb-0">
          <div className="breadCrumb">
            <div className="breadCrumbText">
              <Link to="/bgsw">HOME</Link>&nbsp;/&nbsp; TRACK YOUR FORESTS
            </div>
          </div>
          <div className="container">

          
            <div className="row">
              <div className="col-lg-6 col-md-6 lftContent">
                <h3 className="title-theme-heading text-bgsw">
                  {" "}
                  TRACK <span className="dis-block"> YOUR FORESTS </span>{" "}
                </h3>

                <p className="content-p-text banner-p-content">
                  {" "}
                  The three mini-forests planted in Hyderabad, Coimbatore, and
                  Bangalore by Boschlers are being nurtured and cared for
                  optimal growth.
                </p>
                <p className="content-p-text banner-p-content">
                  {" "}
                  Follow the progress of some of these as they grow and get to
                  know the species of plants and the ecosystem that is thriving
                  at each of these forests.
                </p>
                <p>
                  <a
                    class="viewmap-tag"
                    target="_blank"
                    rel="noreferrer"
                    href="https://earth.google.com/earth/d/1IBhRGGCC85Vhm3DEXfP3INpSMGRgr_i_?usp=sharing"
                  >
                    View on Google Earth
                  </a>
                </p>
                {/* <img className='arrow-padding' src={downarrow} alt='bannar tree' /> */}
              </div>
              <div className="col-lg-6 col-md-6 state-locator">
                <img
                  className="img-fluid tree-logo-custom"
                  src={indiaMap}
                  alt="India Map"
                />
                {/* <div className="local-indicators">
                  <div className="bang">
                    <div className="li-img" title="Bangalore" id="1" style={{ left: '35%', top: '70.2%' }}>
                      <img src={indicatorleaf} alt="leaf" />
                    </div>
                    <span>Bangalore</span>
                  </div>
                  <div className="hyde">
                    <div className="li-img" title="Hyderabad" id="1" style={{ left: '36.5%', top: '56.2%' }}>
                      <img src={indicatorleaf} alt="leaf" />
                    </div>
                    <span>Hyderbad</span>
                  </div>
                  <div className="coim">
                    <div className="li-img" title="Coimbatore" id="1" style={{ left: '30.7%', top: '80%' }}>
                      <img src={indicatorleaf} alt="leaf" />
                    </div>
                    <span>Coimbatore</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="title-theme-heading"> Four Mini Forests </h3>
            </div>

            <div className="carousel-section">
              <Carousel
                arrows={true}
                autoPlaySpeed={3000}
                draggable
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                renderButtonGroupOutside={true}
                swipeable
                responsive={responsive}
                customButtonGroup={<ButtonGroup />}
              >
                <div className="card where-card bgsw-card d-flex align-items-stretch">
                  <img
                    src={kanhashanthivanam}
                    className="img-fluid"
                    alt="Shanthivanam"
                  />
                  <div className="card-body where-body">
                    <p className="card-text">
                      <span> Kanha Shanti Vanam, </span> Hyderabad
                    </p>
                    <div>
                      {/*  <Button className='viewmap-tag-left' variant="link" bsPrefix="" onClick={handleShow}>
                    <i class="icon icon-youtube"></i>
              </Button> */}
                    </div>
                    <div>
                      <Link
                        className="viewmap-tag"
                        to="/forests-detail/hyderabad-forest"
                        rel="noreferrer"
                      >
                        View in detail
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card where-card bgsw-card d-flex align-items-stretch">
                  <img
                    src={bangaloresatellite}
                    className="img-fluid"
                    alt="Kathriguppe Village"
                  />
                  <div className="card-body where-body">
                    <p className="card-text">
                      <span> Kathriguppe Village, </span> Anekal Taluk,
                      Bengaluru
                    </p>
                    <div>
                      {/*  <Button className='viewmap-tag-left' variant="link" bsPrefix="" onClick={handleShow}>
                    <i class="icon icon-youtube"></i>
              </Button> */}
                    </div>
                    <div>
                      <Link
                        className="viewmap-tag"
                        to="/forests-detail/bengaluru-forest"
                        rel="noreferrer"
                      >
                        View in detail
                      </Link>
                    </div>
                  </div>
                </div>
               

                <div className="card where-card bgsw-card d-flex align-items-stretch">
                  <img
                    src={punesoil}
                    className="img-fluid"
                    alt="Shanthivanam"
                  />
                  <div className="card-body where-body">
                    <p className="card-text">
                      <span> Gorhe Khurd Village, </span> Pune
                    </p>
                    <div>
                      {/*  <Button className='viewmap-tag-left' variant="link" bsPrefix="" onClick={handleShow}>
                    <i class="icon icon-youtube"></i>
              </Button> */}
                    </div>
                    <div>
                      <Link
                        className="viewmap-tag"
                        to="/forests-detail/pune-forest"
                        rel="noreferrer"
                      >
                        View in detail
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="card where-card bgsw-card d-flex align-items-stretch">
                  <img
                    src={coimbatoresatellite}
                    className="img-fluid"
                    alt="Bosch Annur"
                  />
                  <div className="card-body where-body">
                    <p className="card-text">
                    <b>Annur</b>, Coimbatore 

                    </p>
                    <div>
                      {/*  <Button className='viewmap-tag-left' variant="link" bsPrefix="" onClick={handleShow}>
                    <i class="icon icon-youtube"></i>
              </Button> */}
                    </div>
                    <div>
                      <Link
                        className="viewmap-tag"
                        to="/forests-detail/coimbatore-forest"
                        rel="noreferrer"
                      >
                        View in detail
                      </Link>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/vj49Gmp9-X0"
              title="Glimpses from BGSW Mini Forests with Heartfulness - Hyderabad, Bangalore &amp; Coimbatore"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Boschlayout>
    </>
  );
}
