import React, { useEffect, useRef } from "react";

//import Logo from 'assets/images/logo/forest-logo.png';

import heartfulnessLogo from "assets/images/logo/heartfulness-logo.png";

import fbhgreenLogo from "assets/images/logo/FBH-Final-Logo.png";

// import boschLogo from 'assets/images/logo/bosch-logo.png';

import boschfuture from "assets/images/logo/bosch-alt-logo.png";

import { Navbar, Nav } from "react-bootstrap";

import { Link } from "gatsby";

const Header = ({ isSticky }) => {
  const ref = useRef(null);

  const scrollToSection = () => {
    document.querySelectorAll("a.scroller").forEach(function (a) {
      a.addEventListener("click", function (event) {
        if (event.target.getAttribute("href")) {
          event.preventDefault();
          const hash = event.target.getAttribute("href");
          const hashSection = hash.split("/#").pop();
          const scrollTarget = document.querySelector(`#${hashSection}`);
          const headerHeight = 60;
          if (scrollTarget) {
            window.scrollTo({
              top: scrollTarget.offsetTop - headerHeight,
              behavior: "smooth",
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    scrollToSection();
    if (isSticky) {
      const handleScroll = () => {
        if (ref.current && ref.current.classList) {
          if (window.scrollY > 10) {
            ref.current.classList.add("sticky");
          } else {
            ref.current.classList.remove("sticky");
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", () => handleScroll);
      };
    }
  }, []);

  return (
    <header
      id="header"
      className={`header-section ${isSticky ? "" : "sticky"}`}
      ref={ref}
    >
      <div className="top-menu boschmenu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand>
                  <span className="mobi-logo">
                    <Link to="/bgsw">
                      <img src={boschfuture} alt="heartfulness Logo" />
                    </Link>
                  </span>
                  <span className="vertical-separate"></span>
                  <span className="heartfulness-logo">
                    <Link to="/bgsw">
                      <img src={fbhgreenLogo} alt="FBH Logo" />
                    </Link>
                  </span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  {isSticky ? (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Link
                        to="/bgsw/#bosch-banner"
                        className="custom-nav-link"
                      >
                        About
                      </Link>
                      <Link
                        to="/bgsw/#objectives-forests"
                        className="custom-nav-link"
                      >
                        Objectives
                      </Link>
                      <Link to="/bgsw/#where-when" className="custom-nav-link">
                        Events
                      </Link>
                      {/*  <Link to='/bgsw/#projectsunique' className='custom-nav-link'>
                        Why is this project unique
                  </Link>*/}

                      <Link
                        to="/bgsw/#project-execute"
                        className="custom-nav-link"
                      >
                        Project Execution
                      </Link>
                      <Link
                        to="/track-your-forests"
                        className="custom-nav-link"
                      >
                        Track Your Forests
                      </Link>
                      <Nav.Link href="/#contact" className="scroller">
                        Contact
                      </Nav.Link>
                      <Link
                        to="/bgsw/bgswreport"
                        className="custom-nav-link"
                      >
                        Reports
                      </Link>
                      <Nav.Link
                        href="https://cdn-prod.heartfulness.org/BGSW/Forest%20Plantation%20drive_booklet%20(1).pdf"
                        target="blank"
                      >
                        Brochure
                      </Nav.Link>
                      {/* <Link to='/faqs' className='nav-link'>
                        FAQs
                      </Link> */}
                    </Nav>
                  ) : (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Link
                        to="/bgsw/#bosch-banner"
                        className="custom-nav-link"
                      >
                        About
                      </Link>
                      <Link
                        to="/bgsw/#objectives-forests"
                        className="custom-nav-link"
                      >
                        Objectives
                      </Link>
                      <Link to="/bgsw/#where-when" className="custom-nav-link">
                        Events
                      </Link>
                      {/*  <Link
                        to="/bgsw/#projectsunique"
                        className="custom-nav-link"
                      >
                        Why is this project unique
                  </Link>*/}

                      <Link
                        to="/bgsw/#project-execute"
                        className="custom-nav-link"
                      >
                        Project Execution
                      </Link>
                      <Link
                        to="/track-your-forests"
                        className="custom-nav-link"
                      >
                        Track Your Forests
                      </Link>
                      {/* <Link to='/' className='nav-link'>
                        Contact
                      </Link> */}
                      <Nav.Link href="/#contact" className="scroller">
                        Contact
                      </Nav.Link>
                      <Link
                        to="/bgsw/bgswreport"
                        className="custom-nav-link"
                      >
                        Reports
                      </Link>
                      <Nav.Link
                        href="https://cdn-prod.heartfulness.org/BGSW/Forest%20Plantation%20drive_booklet%20(1).pdf"
                        target="blank"
                      >
                        Brochure
                      </Nav.Link>
                      {/* <Link to='/faqs' className='nav-link'>
                        FAQs
                      </Link> */}
                    </Nav>
                  )}
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
